<template>
  <SimpleLayout custom>
    <el-container>
      <el-aside v-if="menu.length > 0" :width="asideWidth">
        <YSimpleMenu :menu="menu"></YSimpleMenu>
      </el-aside>
      <el-main style="padding: 0;">
        <YMainBody>
          <slot></slot>
        </YMainBody>
      </el-main>
    </el-container>
  </SimpleLayout>
</template>

<script>
import SimpleLayout from "@/components/layouts/SimpleLayout";
import YSimpleMenu from "@/components/layouts/YSimpleMenu";
import YMainBody from "@/components/layouts/YMainBody";
export default {
  name: "YLayoutBodyMenu",
  components: {YMainBody, YSimpleMenu, SimpleLayout},
  props:{
    asideWidth:{
      type:String,
      default:"6rem"
    },
    menu:{
      type:Array,
      default(){
        return []
      }
    },
  }
}
</script>

<style scoped>

</style>