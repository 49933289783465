<template>
  <el-menu  router mode="horizontal" :default-active="path">
    <el-menu-item v-for="(item,key) in vuex_menu" :key="key" :index="item.path">
      <i style="font-weight: 600" :class="item.icon"></i>
      {{ item.title }}
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: "SimpleHeadMenu",
  data(){
    return{
      path:"",
    }
  },
  created() {
    this.onRouteChanged()
  },
  watch: {
    '$route': 'onRouteChanged'
  },
  methods:{
    onRouteChanged(){
      this.path = this.$route.path.split("/")?.slice(0,2)?.join("/");
    },
  },
}
</script>

<style scoped>

</style>